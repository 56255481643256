import {
  ALERTBLAST_DOMAIN_NUMBERS,
  ALERTBLAST_USER,
  CREATE_ALERTBLAST_DOMAIN,
  GET_CUSTOMER_RESELLER_UCAAS_ADDRESS__LIST_URL,
  GET_CUSTOMER_RESELLER_UCAAS_SINGLE_ADDRESS_URL,
  UPDATE_RESELLER_CUSTOMERS_USERS,
  VALIDATE_CUSTOMER_RESELLER_UCAAS_ADDRESS_DETAILS_URL
} from "./../../constants/ConstAPI"
/* eslint-disable @typescript-eslint/no-explicit-any */
// PROJECT IMPORTS
import {
  CUSTOMER,
  CUSTOMER_EDIT,
  CUSTOMER_FIRST_NAME_DIRECTORY,
  DOMAIN_VALIDATE,
  INVENTORY_EXPORT,
  GET_ZOHO_ACCOUNT,
  CUSTOMER_SUMMARY,
  EXTENSION_VALIDATE,
  RESELLER_CUSTOMERS_USERS,
  USER_PERMISSIONS,
  CREATE_CUSTOMER_RESELLER_UCAAS_URL
} from "../../constants/ConstAPI"
import { apiInstance } from "../../services/api"
import { BaseParams } from "../../types/customer"

/* ============================== INVENTORY SERVICES ============================== */

export const customerListGet = (params: any) => {
  return apiInstance.get(CUSTOMER, { params })
}

export const customerusersListGet = (params: any) => {
  return apiInstance.get(
    `${CUSTOMER}/resellers/${params.reseller}/domains/${params.domain}/users`,
    { params }
  )
}

export const customerAdd = (payload: any) => {
  return apiInstance.post(CUSTOMER, payload)
}

export const customerEdit = (payload: any) => {
  return apiInstance.put(CUSTOMER_EDIT(payload.id), payload)
}

export const updatecontactDirectory = (payload: any) => {
  return apiInstance.post(`${CUSTOMER_FIRST_NAME_DIRECTORY}/${payload.id}`)
}

export const inventoryListExport = () => {
  return apiInstance.get(INVENTORY_EXPORT)
}

export const domainValidatePost = (payload: any) => {
  return apiInstance.post(DOMAIN_VALIDATE, payload, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" }
  })
}

export const extensionValidatePost = (payload: any) => {
  return apiInstance.post(
    EXTENSION_VALIDATE(payload.reseller, payload.domain),
    payload,
    {
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    }
  )
}

export const accountZohoGet = (params: { crm_account: string }) => {
  return apiInstance.get(GET_ZOHO_ACCOUNT, { params })
}

export const customerSummaryGet = () => {
  return apiInstance.get(CUSTOMER_SUMMARY)
}

export const resellerCustomerUserPost = (payload: any, customer_info: any) => {
  return apiInstance.post(
    RESELLER_CUSTOMERS_USERS(customer_info.reseller, customer_info.domain),
    payload
  )
}

export const resellerCustomerUserPut = (
  payload: any,
  customer_info: any,
  userId: number
) => {
  return apiInstance.put(
    UPDATE_RESELLER_CUSTOMERS_USERS(
      customer_info.reseller,
      customer_info.domain,
      userId
    ),
    payload
  )
}

export const userPermissionsGet = (payload: any) => {
  return apiInstance.get(
    USER_PERMISSIONS(payload.reseller, payload.domain, payload.user_id),
    payload
  )
}

export const createCustomerResellerUcaasSitePost = (
  payload: any,
  customer_info: any
) => {
  return apiInstance.post(
    CREATE_CUSTOMER_RESELLER_UCAAS_URL(
      customer_info.reseller,
      customer_info.domain
    ),
    payload
  )
}

export const updateCustomerResellerUcaasSitePut = (
  payload: any,
  customer_info: any
) => {
  return apiInstance.put(
    CREATE_CUSTOMER_RESELLER_UCAAS_URL(
      customer_info.reseller,
      customer_info.domain
    ),
    payload
  )
}

export const getCustomerResellerUcaasSiteListGet = (params: any) => {
  return apiInstance.get(
    CREATE_CUSTOMER_RESELLER_UCAAS_URL(params.reseller, params.domain),
    { params }
  )
}

export const getCustomerResellerUcaasSiteAddressListGet = (params: any) => {
  return apiInstance.get(
    GET_CUSTOMER_RESELLER_UCAAS_ADDRESS__LIST_URL(
      params.reseller,
      params.domain
    )
  )
}

export const getCustomerResellerUcaasSiteSingleAddressGet = (params: any) => {
  return apiInstance.get(
    GET_CUSTOMER_RESELLER_UCAAS_SINGLE_ADDRESS_URL(
      params.reseller,
      params.domain,
      params.emergency_address_id
    )
  )
}

export const validateCustomerResellerUcaasSiteAddressDetailsPost = (
  payload: any,
  customer_info: any
) => {
  return apiInstance.post(
    VALIDATE_CUSTOMER_RESELLER_UCAAS_ADDRESS_DETAILS_URL(
      customer_info.reseller,
      customer_info.domain
    ),
    payload
  )
}

export const createAlertblast = (reseller: string, domain: string) => {
  return apiInstance.post(CREATE_ALERTBLAST_DOMAIN(reseller, domain))
}

export const createAlertblastUser = (
  userId: number,
  reseller: string,
  domain: string
) => {
  return apiInstance.post(ALERTBLAST_USER(userId, reseller, domain))
}

export const fetchAlertblastUser = (
  userId: number,
  reseller: string,
  domain: string
) => {
  return apiInstance.get(ALERTBLAST_USER(userId, reseller, domain))
}

export const AlertblastUserUpdate = (
  userId: number,
  reseller: string,
  domain: string,
  payload: { assigned_numbers: string[] }
) => {
  return apiInstance.put(ALERTBLAST_USER(userId, reseller, domain), payload)
}

export const AlertblastUserDelete = (
  userId: number,
  reseller: string,
  domain: string
) => {
  return apiInstance.delete(ALERTBLAST_USER(userId, reseller, domain))
}

export const alertblastDomainNumbers = (
  reseller: string,
  domain: string,
  params: BaseParams
) => {
  return apiInstance.get(ALERTBLAST_DOMAIN_NUMBERS(reseller, domain), {
    params
  })
}
