import axios from "axios"
import {
  GET_DOMAIN_USERS,
  GET_ENABLED_INTEGRATIONS,
  GET_INTEGRATIONS,
  GET_SELECTED_DOMAIN_USERS,
  Integration,
  UCAAS_AUTH,
  UCAAS_AUTH_INTEGRATION,
  USER_INTEGRATION
} from "../../constants/ConstAPI"
import { apiInstance } from "../../services/api"
import { FormValues, IntegrationName } from "../../types/integrations"

export const getUcaasAuthToken = () => {
  return apiInstance.post(UCAAS_AUTH)
}

export const postIntegration = ({
  integrationName,
  usersDomain,
  nsToken,
  payload
}: {
  integrationName: IntegrationName
  usersDomain: string
  nsToken: string
  payload: FormValues
}) => {
  return axios.post(
    `${import.meta.env?.VITE_API_SERVER_URL}${Integration(integrationName, usersDomain)}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${nsToken}`
      }
    }
  )
}

export const putIntegration = ({
  integrationName,
  usersDomain,
  nsToken,
  payload
}: {
  integrationName: IntegrationName
  usersDomain: string
  nsToken: string
  payload: { enabled:string; log_string?: string }
}) => {
  return axios.put(
    `${import.meta.env?.VITE_API_SERVER_URL}${Integration(integrationName, usersDomain)}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${nsToken}`
      }
    }
  )
}

export const getDomainIntegration = async ({
  integrationName,
  usersDomain,
  nsToken
}: {
  integrationName: IntegrationName
  usersDomain: string
  nsToken: string
}) => {
  const response = await axios.get(
    `${import.meta.env?.VITE_API_SERVER_URL}${Integration(integrationName, usersDomain)}`,
    {
      headers: {
        Authorization: `Bearer ${nsToken}`
      }
    }
  )
  return response?.data?.data
}

export const deleteIntegration = ({
  integrationName,
  usersDomain,
  nsToken
}: {
  integrationName: IntegrationName
  usersDomain: string
  nsToken: string
}) => {
  return axios.delete(
    `${import.meta.env?.VITE_API_SERVER_URL}${Integration(integrationName, usersDomain)}`,
    {
      headers: {
        Authorization: `Bearer ${nsToken}`
      }
    }
  )
}

export const getIntegrations = async (nsToken: string) => {
  const response = await axios.get(
    `${import.meta.env?.VITE_API_SERVER_URL}${GET_INTEGRATIONS}`,
    {
      headers: {
        Authorization: `Bearer ${nsToken}`
      }
    }
  )
  return response?.data?.data
}

export const getDomainUsers = async ({
  usersDomain,
  nsToken,
  searchWord = ""
}: {
  usersDomain: string
  nsToken: string
  searchWord?: string
}) => {
  const response = await axios.get(
    `${import.meta.env?.VITE_API_SERVER_URL}${GET_DOMAIN_USERS(usersDomain)}`,
    {
      params: {
        search_word: searchWord
      },
      headers: {
        Authorization: `Bearer ${nsToken}`
      }
    }
  )
  return response?.data?.data
}

export const addUserIntegration = ({
  integrationName,
  usersDomain,
  nsToken,
  userId,
  payload
}: {
  integrationName: IntegrationName
  usersDomain: string
  nsToken: string
  userId: string
  payload: { integration_type: string[] }
}) => {
  return axios.post(
    `${import.meta.env?.VITE_API_SERVER_URL}${USER_INTEGRATION({ integrationName, usersDomain, userId })}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${nsToken}`
      }
    }
  )
}

export const deleteUserIntegration = ({
  integrationName,
  usersDomain,
  nsToken,
  userId
}: {
  integrationName: IntegrationName
  usersDomain: string
  nsToken: string
  userId: string
}) => {
  return axios.delete(
    `${import.meta.env?.VITE_API_SERVER_URL}${USER_INTEGRATION({ integrationName, usersDomain, userId })}`,
    {
      headers: {
        Authorization: `Bearer ${nsToken}`
      }
    }
  )
}

export const getAuthRedirectURL = ({
  integrationName,
  usersDomain,
  nsToken
}: {
  integrationName: IntegrationName
  usersDomain: string
  nsToken: string
}) => {
  return axios.get(
    `${import.meta.env?.VITE_API_SERVER_URL}${UCAAS_AUTH_INTEGRATION(integrationName, usersDomain)}`,
    {
      headers: {
        Authorization: `Bearer ${nsToken}`
      }
    }
  )
}

export const getEnabledIntegrations = async ({
  usersDomain,
  nsToken
}: {
  usersDomain: string
  nsToken: string
}) => {
  const response = await axios.get(
    `${
      import.meta.env?.VITE_API_SERVER_URL
    }${GET_ENABLED_INTEGRATIONS(usersDomain)}`,
    {
      headers: {
        Authorization: `Bearer ${nsToken}`
      }
    }
  )

  return response?.data?.data
}

export const getSelectedDomainUsers = async ({
  integrationName,
  usersDomain,
  nsToken
}: {
  integrationName: IntegrationName
  usersDomain: string
  nsToken: string
}) => {
  const response = await axios.get(
    `${
      import.meta.env?.VITE_API_SERVER_URL
    }${GET_SELECTED_DOMAIN_USERS(integrationName, usersDomain)}`,
    {
      headers: {
        Authorization: `Bearer ${nsToken}`
      }
    }
  )

  return response?.data?.data
}
