import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getUcaasAuthToken } from "./integrationService"

interface InitialState {
    nsToken: string
}

const initialState:InitialState = {
    nsToken: ""
}

export const getUcaasAuth = createAsyncThunk('ucaas/auth', async () => {
    return await getUcaasAuthToken()
})

const intergrationSlice = createSlice({
    name: "integration",
    initialState,
    reducers:{},
    extraReducers:(builder) => {
        builder.addCase(getUcaasAuth.fulfilled, (state, action) => {
            state.nsToken = action.payload?.data?.access_token
        })
    }
})

export default intergrationSlice.reducer