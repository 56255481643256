/* eslint-disable import/no-cycle */
import { configureStore } from "@reduxjs/toolkit"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/es/storage"
import ThemeReducer from "../pages/Accounts/SelectTheme/ThemeSlice"
import inventorySlice from "../pages/inventory/inventorySlice"
import customerSlice from "../pages/customer/customerSlice"
import secureFaxSlice from "../pages/sentFaxAdmin/secureFaxSlice"
import partnerSlice from "../pages/partner/partnerSlice"
import ordersSlice from "../pages/orders/ordersSlice"
import userRolesSlice from "../pages/userRoles/userRolesSlice"
import resellerSlice from "../pages/reseller/resellerSlice"
import trainingSlice from "../pages/Training/TrainingSlice"
import ucaasSlice from "../pages/ucaas/ucaasSlice"
import integrationSlice from "../pages/Integrations/integrationSlice"

type PartnerState = ReturnType<typeof partnerSlice> // Infer the state type from the slice
type ThemeState = ReturnType<typeof ThemeReducer> // Infer the state type from the slice

const themePersistConfig = {
  key: "theme",
  storage
}

const partnerPersistConfig = {
  key: "partner",
  storage,
  blacklist: ["isPartnerOnboarding"]
}

const persistedPartnerReducer = persistReducer<PartnerState>(
  partnerPersistConfig,
  partnerSlice
)

const persistedThemeReducer = persistReducer<ThemeState>(
  themePersistConfig,
  ThemeReducer
)

export const store = configureStore({
  reducer: {
    inventory: inventorySlice,
    customer: customerSlice,
    secureFax: secureFaxSlice,
    partner: persistedPartnerReducer,
    userRoles: userRolesSlice,
    orders: ordersSlice,
    reseller: resellerSlice,
    training: trainingSlice,
    ucaas: ucaasSlice,
    theme: persistedThemeReducer,
    integration: integrationSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)
